var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pages"},[_c('van-nav-bar',{attrs:{"title":"央视电视剧频道广告"}}),_c('div',[_c('van-swipe',{on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"indicator",fn:function(){return [_c('div',{staticClass:"custom-indicator"},[_vm._v(" "+_vm._s(_vm.current + 1)+"/"+_vm._s(_vm.detail.img.length)+" ")])]},proxy:true}])},_vm._l((_vm.detail.img),function(item,index){return _c('van-swipe-item',{key:item},[_c('van-image',{staticClass:"bgimg1",attrs:{"src":_vm.detail.img[index],"fit":"contain"}})],1)}),1)],1),_c('div',{staticClass:"part"},[_c('div',{staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(_vm.detail.serviceName))]),_c('div',{staticClass:"goup"},[_c('div',{staticStyle:{"font-weight":"bold"}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("￥")]),_vm._v(_vm._s(_vm.detail.advertisingOut)+" ")]),_c('div',{staticStyle:{"font-size":"12px","color":"#999"}},[_vm._v(" "+_vm._s(_vm.detail.peopleNum)+"人报名 ")])])]),_c('div',{staticStyle:{"width":"100%","height":"10px"}}),_c('div',{staticClass:"part"},[_c('div',{staticStyle:{"font-size":"20px"}},[_vm._v("栏目介绍")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.detail.advertisingIntroduction)}})]),_c('van-row',[_c('div',{staticClass:"bottom-btn"},[_c('van-col',{attrs:{"span":12}},[_c('van-button',{staticStyle:{"border-radius":"10px 0 0 10px"},attrs:{"color":"#313131","block":""},on:{"click":function($event){return _vm.openApp(
              'jump?type=2&id=' +
                _vm.$route.query.id +
                '&userId=' +
                _vm.$route.query.userId,
              true
            )}}},[_c('div',{staticClass:"align"},[_c('img',{attrs:{"src":require("../../assets/BeiDa/电话.png")}}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v("联系客服")])])])],1),_c('van-col',{attrs:{"span":12}},[_c('van-button',{staticStyle:{"border-radius":"0 10px 10px 0"},attrs:{"color":"linear-gradient(to right,#FF7200, #FF3C00)","block":""},on:{"click":function($event){return _vm.openApp(
              'jump?type=2&id=' +
                _vm.$route.query.id +
                '&userId=' +
                _vm.$route.query.userId,
              true
            )}}},[_c('div',{staticClass:"align"},[_c('img',{attrs:{"src":require("../../assets/BeiDa/订单.png")}}),_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v("立即申请")])])])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }