<template>
  <div class="pages">
    <van-nav-bar title="央视电视剧频道广告"> </van-nav-bar>
    <div>
      <van-swipe @change="onChange">
        <van-swipe-item v-for="(item, index) in detail.img" :key="item">
          <van-image class="bgimg1" :src="detail.img[index]" fit="contain"
        /></van-swipe-item>
        <template #indicator>
          <div class="custom-indicator">
            {{ current + 1 }}/{{ detail.img.length }}
          </div>
        </template>
      </van-swipe>
    </div>
    <div class="part">
      <div style="font-size: 16px">{{ detail.serviceName }}</div>
      <div class="goup">
        <div style="font-weight: bold">
          <span style="font-size: 12px">￥</span>{{ detail.advertisingOut }}
        </div>
        <div style="font-size: 12px; color: #999">
          {{ detail.peopleNum }}人报名
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 10px"></div>
    <!-- 中间切换 -->
    <div class="part">
      <div style="font-size: 20px">栏目介绍</div>
      <div v-html="detail.advertisingIntroduction"></div>
    </div>
    <van-row>
      <div class="bottom-btn">
        <van-col :span="12">
          <van-button
            color="#313131"
            block
            style="border-radius: 10px 0 0 10px"
            @click="
              openApp(
                'jump?type=2&id=' +
                  $route.query.id +
                  '&userId=' +
                  $route.query.userId,
                true
              )
            "
          >
            <div class="align">
              <img src="../../assets/BeiDa/电话.png" /><span
                style="margin-left: 10px"
                >联系客服</span
              >
            </div>
          </van-button>
        </van-col>
        <van-col :span="12">
          <van-button
            @click="
              openApp(
                'jump?type=2&id=' +
                  $route.query.id +
                  '&userId=' +
                  $route.query.userId,
                true
              )
            "
            color="linear-gradient(to right,#FF7200, #FF3C00)"
            block
            style="border-radius: 0 10px 10px 0"
          >
            <div class="align">
              <img src="../../assets/BeiDa/订单.png" /><span
                style="margin-left: 10px"
                >立即申请</span
              >
            </div>
          </van-button>
          <!-- <van-action-sheet v-model="show" title="广告申请">
            <div>
              <van-cell :border="false">
                <van-row>
                  <van-col span="8" style="font-size: 14px; color: #adadad">
                    <img
                      src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng5a2b214886584d30438958b35f6e49036ac75bbd053e2d72abfacdcb3c5236c3"
                      alt=""
                    />
                  </van-col>
                  <van-col span="16" style="font-size: 17px; color: #adadad">
                    <div class="goup">
                      <div style="color: rgba(168, 0, 0, 1)">
                        ￥<span style="font-size: 28px">30000</span>
                      </div>
                    </div>
                    <div
                      style="
                        margin-top: 54px;
                        font-size: 12px;
                        color: rgba(51, 51, 51, 1);
                      "
                    >
                      已选择：{{ server }}
                    </div>
                  </van-col>
                </van-row>
              </van-cell>

              <van-cell>
                <div style="font-size: 14px; color: rgba(51, 51, 51, 1)">
                  服务形式
                </div>
                <div
                  class="default button"
                  :class="{ active: isActive[0] }"
                  @click="setServer('广告投放', 0)"
                >
                  广告投放
                </div>
                <div
                  class="default button"
                  :class="{ active: isActive[1] }"
                  @click="setServer('广告拍摄', 1)"
                >
                  广告拍摄
                </div>
                <div
                  class="default button"
                  :class="{ active: isActive[2] }"
                  @click="setServer('套餐组合', 2)"
                >
                  套餐组合
                </div>
              </van-cell>
              <van-cell>
                <van-button
                  color="linear-gradient(to right,#FF7200, #FF3C00)"
                  block
                  style="border-radius: 18px"
                >
                  立即申请</van-button
                >
              </van-cell>
            </div>
          </van-action-sheet> -->
        </van-col>
      </div>
    </van-row>
  </div>
</template>
<script>
import { cctvDetail } from "@/api/cctVideo";
export default {
  name: "cctVideo",
  data() {
    return {
      show: false,
      isActive: [true, false, false],
      server: "广告投放",
      id: null,
      detail: null,
      current: 0,
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.openApp(
      "jump?type=2&id=" +
        this.$route.query.id +
        "&userId=" +
        this.$route.query.userId,
      true
    );
    console.log(cctvDetail);
    this.fetchDetail();
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
    onClickLeft() {
      this.$router.back();
    },
    setServer(name, id) {
      this.server = name;
      this.isActive[0] = false;
      this.isActive[1] = false;
      this.isActive[2] = false;
      this.isActive[id] = true;
    },
    fetchDetail() {
      cctvDetail(this.id).then((res) => {
        console.log(res, "cctvDetail");
        if (res.data.code === 0) {
          this.detail = res.data.data;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.pages {
  min-width: 320px;
  padding-bottom: 90px;
}
.bgimg1 {
  width: 100%;
}
.part {
  margin: 3px 10px;
  background-color: #fff;
}
.goup {
  color: #ff5200;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.default {
  color: rgba(153, 153, 153, 1);
  background-color: rgba(238, 238, 238, 1);
}
.active {
  color: white;
  background-color: #ff5200;
}
.button {
  width: 78px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  display: inline-block;
  margin-right: 10px;
}
.bottom-btn {
  width: 100%;
  padding: 10px 16px 44px 16px;
  box-sizing: border-box;
  background: white;
  display: flex;
  position: fixed;
  bottom: 0px;
}
.yellow-xhx {
  width: 10px;
  height: 3px;
  background-color: #ff5200;
  border-radius: 1.5px;
  margin: 0px auto;
}
.align {
  display: flex;
  // justify-content: center;
  align-items: center;
}
.input-p {
  font-size: 17px;
  color: #333;
  margin-left: 0;
  padding-left: 0;
}
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
}
</style>
